@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
/*@import url('https://fonts.googleapis.com/css?family=Lato');*/
/*@import url('https://fonts.googleapis.com/css?family=Open%20Sans');*/

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: '14px';
      font-weight: 'normal';
}

.myfont{
    font-family: "Open Sans", sans-serif !important;
}


code {
    font-family: "Open Sans", sans-serif;
}


ul {
  list-style-type: none;
}

li {
  display: inline-block;
}

input[type="checkbox"][id^="cb"] {
  display: none;
}

.imgcheckbox {
  /* border: 1px solid #fff; */
  padding: 10px;
  display: block;
  position: relative;
  margin: 10px;
  cursor: pointer;
}

.imgcheckbox:before {
  background-color: white;
  color: white;
  content: " ";
  display: block;
  border-radius: 50%;
  border: 1px solid #1bd229;
  position: absolute;
  top: -5px;
  left: -5px;
  width: 29px;
  height: 29px;
  text-align: center;
  line-height: 28px;
  transition-duration: 0.4s;
  transform: scale(0);
}

.imgcheckbox img {
  height: 100px;
  width: 100px;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
}

:checked + .imgcheckbox {
  border-color: #ddd;
}

:checked + .imgcheckbox:before {
  content: "✓";
  background-color: #1bd229;
  transform: scale(1);
}

:checked + .imgcheckbox img {
  transform: scale(0.9);
  /* box-shadow: 0 0 5px #333; */
  z-index: -1;
}
