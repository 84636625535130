@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
/*@import url('https://fonts.googleapis.com/css?family=Lato');*/
/*@import url('https://fonts.googleapis.com/css?family=Open%20Sans');*/

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: '14px';
      font-weight: 'normal';
}

.myfont{
    font-family: "Open Sans", sans-serif !important;
}


code {
    font-family: "Open Sans", sans-serif;
}
